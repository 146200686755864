<template>
<div>
  <b-container>
    <b-row>
      <b-col>
        <div>
        <b-form @submit="search">
        <b-form-group horizontal class="mt-4">
          <b-input-group>
            <b-form-input v-model="q" placeholder="Type to search" />
              <b-btn :disabled="!q" @click="search">search</b-btn>
          </b-input-group>
        </b-form-group>
        </b-form>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="!loadingUnified">
      <b-col>
        <div v-if="results.cases" class="my-4">
          <div v-if="results.cases.length > 0">
            <h3>Cases</h3>
            <b-card>
              <div class="mb-1" v-for="item in results.cases" :key="'case-' + item.id">
                <b-link :to="{ name: 'Case', params: { id: item.id } }">{{item.name}}</b-link>
              </div>
            </b-card>
          </div>
        </div>
        <div v-if="results.controls" class="my-4">
          <div v-if="results.controls.length > 0">
            <h3>Controls</h3>
            <b-card>
              <div class="mb-1" v-for="item in results.controls" :key="'control-' + item.id">
                <b-link :to="{ name: 'Control', params: { id: item.id } }">{{item.name}}</b-link>
              </div>
            </b-card>
          </div>
        </div>
        <div v-if="results.experts" class="my-4">
          <div v-if="results.experts.length > 0">
            <h3>Experts</h3>
            <b-card>
              <div class="mb-1" v-for="item in results.experts" :key="'expert-' + item.id">
                <b-link :to="{ name: 'ImpactExpert', params: { id: item.id } }">{{item.forename}} {{item.surname}}</b-link>
              </div>
            </b-card>
          </div>
        </div>
        <div v-if="results.events" class="my-4">
          <div v-if="results.events.length > 0">
            <h3>Events</h3>
            <b-card>
              <div class="mb-1" v-for="item in results.events" :key="'event-' + item.id">
                <b-link :to="{ name: 'Event', params: { id: item.id } }">{{item.name}}</b-link>
              </div>
            </b-card>
          </div>
        </div>
        <div v-if="results.initiatives" class="my-4">
          <div v-if="results.initiatives.length > 0">
            <h3>Initiatives</h3>
            <b-card>
              <div class="mb-1" v-for="item in results.initiatives" :key="'initiative-' + item.id">
                <b-link :to="{ name: 'ImpactInitiative', params: { id: item.id } }">{{item.name}}</b-link>
              </div>
            </b-card>
          </div>
        </div>
        <div v-if="results.articles">
          <div v-if="results.articles.length > 0">
            <h3>Insights</h3>
            <b-card>
              <div class="mb-1" v-for="item in results.articles" :key="'article-' + item.id">
                <b-link :to="{ name: 'Article', params: { id: item.id } }">{{item.title}}</b-link>
              </div>
            </b-card>
          </div>
        </div>
        <div v-if="results.obligations" class="my-4">
          <div v-if="results.obligations.length > 0">
            <h3>Obligations</h3>
            <b-card>
              <div class="mb-1" v-for="item in results.obligations" :key="'obligation-' + item.id">
                <b-link :to="{ name: 'ImpactObligation', params: { id: item.id } }">{{item.name}}</b-link>
              </div>
            </b-card>
          </div>
        </div>
        <div v-if="results.regulations" class="my-4">
          <div v-if="results.regulations.length > 0">
            <h3>Regulations</h3>
            <b-card>
              <div class="mb-1" v-for="item in results.regulations" :key="'regulation-' + item.id">
                <b-link :to="{ name: 'RegDecompRegulation', params: { id: item.id } }">{{item.name_short}}</b-link>
              </div>
            </b-card>
          </div>
        </div>
        <div v-if="results.risks" class="my-4">
          <div v-if="results.risks.length > 0">
            <h3>Risks</h3>
            <b-card>
              <div class="mb-1" v-for="item in results.risks" :key="'risk-' + item.id">
                <b-link :to="{ name: 'Risk', params: { id: item.id } }">{{item.name}}</b-link>
              </div>
            </b-card>
          </div>
        </div>
        <div v-if="results.topics" class="my-4">
          <div v-if="results.topics.length > 0">
            <h3>Topics</h3>
            <b-card>
              <div class="mb-1" v-for="item in results.topics" :key="'topic-' + item.id">
                <b-link :to="{ name: 'Topic', params: { id: item.id } }">{{item.name}}</b-link>
              </div>
            </b-card>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-else class="text-center mb-4" align-h="center">
      <b-col cols="1">
        <div><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="!loadingNews">
      <b-col>
        <h1>News</h1>
        <div>
          <b-form-group label="Sorting by">
            <b-form-radio-group
              v-model="sortingSelected"
              :options="sortingOptions"
              name="radio-sorting"
            ></b-form-radio-group>
          </b-form-group>
        </div>
        <b-card class="mb-3" v-for="item in news" :key="item.id" :id="item.id">
          <publication
            :propItem="item"
            :key="item.id"
            :tagsProp=tags
            :username="user.username"
            :permissionAddArticle="permissionAddArticle"
            :permissionAddRisk="permissionAddRisk"
            :permissionAddTag="permissionAddTag"
            :permissionArticlesWip="permissionArticlesWip"
            :permissionDeletePublication="permissionDeletePublication"
            :permissionInsights="permissionInsights"
            :permissionReadTagsPanel="permissionReadTagsPanel"
             />
        </b-card>
      </b-col>
    </b-row>
    <b-row v-else class="text-center mb-4" align-h="center">
      <b-col cols="1">
        <div><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import ac from '../libs/accesscontrol'
import Publication from '@/components/PublicationNew'

export default {
  components: {
    Publication
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    this.permissionAddArticle = ac.can(this.user.acgroups).createAny('article').granted
    this.permissionAddRisk = ac.can(this.user.acgroups).createAny('marrisk').granted
    this.permissionAddTag = ac.can(this.user.acgroups).createAny('newstag').granted
    this.permissionArticlesWip = ac.can(this.user.acgroups).createAny('articleWorkflowEdit').granted
    this.permissionDeletePublication = ac.can(this.user.acgroups).deleteAny('publication').granted
    this.permissionInsights = true
    this.permissionReadAnalytics = ac.can(this.user.acgroups).readAny('analytics').granted
    this.permissionReadTagsPanel = ac.can(this.user.acgroups).readAny('tagspanel').granted
    this.unifiedParams = {
      searchTerm: this.q,
      articles: true,
      cases: ac.can(this.user.acgroups).readAny('case').granted,
      controls: ac.can(this.user.acgroups).readAny('control').granted,
      experts: true,
      events: true,
      initiatives: true,
      obligations: ac.can(this.user.acgroups).readAny('impactobligation').granted,
      regulations: ac.can(this.user.acgroups).readAny('regulation').granted,
      risks: ac.can(this.user.acgroups).readAny('marrisk').granted,
      topics: true
    }
    if (this.$config.BUILD === 'external') {
      this.permissionAddRisk = false
      this.permissionAddTag = false
      this.permissionDeletePublication = false
      this.permissionInsights = ac.can(this.user.acgroups).readAny('article').granted
      this.permissionReadAnalytics = false
      this.permissionReadTagsPanel = false
      this.unifiedParams = {
        searchTerm: this.q,
        articles: ac.can(this.user.acgroups).readAny('article').granted,
        events: true,
        risks: ac.can(this.user.acgroups).readAny('marrisk').granted,
        topics: true
      }
    }
    try {
      let apiName = 'cosmos'
      let path = `/tags`
      this.tags = await this.$Amplify.API.get(apiName, path)
    } catch (e) {
      this.$logger.warn('error getting events for a day', e)
    }
    if (this.$route.query.o) {
      if (this.$route.query.o === 'time') {
        this.sortingSelected = 'time'
      } else {
        this.sortingSelected = 'rank'
      }
    } else {
      const options = window.localStorage.getItem('frmCloudSearchSorting')
      if (options) {
        this.sortingSelected = JSON.parse(options)
      }
    }
    this.load()
  },
  data () {
    return {
      authToken: '',
      gridLoaderColor: 'black',
      gridLoaderSize: '10px',
      loadingNews: true,
      loadingUnified: true,
      news: [],
      permissionAddArticle: false,
      permissionAddRisk: false,
      permissionAddTag: false,
      permissionArticlesWip: false,
      permissionDeletePublication: false,
      permissionReadAnalytics: false,
      permissionReadTagsPanel: false,
      results: {},
      sortingOptions: [
        { text: 'By rank', value: 'rank' },
        { text: 'By date', value: 'time' }
      ],
      sortingSelected: 'rank',
      q: '',
      tags: [],
      unifiedParams: null
    }
  },
  methods: {
    load: async function () {
      this.loadingNews = true
      this.loadingUnified = true
      this.q = this.$route.params.q
      this.$logger.debug('load started')
      try {
        const params = {
          body: {
            searchTerm: this.q,
            articles: true,
            cases: ac.can(this.user.acgroups).readAny('case').granted,
            controls: ac.can(this.user.acgroups).readAny('control').granted,
            experts: true,
            events: true,
            initiatives: true,
            obligations: ac.can(this.user.acgroups).readAny('impactobligation').granted,
            regulations: ac.can(this.user.acgroups).readAny('regulation').granted,
            risks: ac.can(this.user.acgroups).readAny('marrisk').granted,
            topics: true
          }
        }
        this.results = await this.$Amplify.API.post('cosmos', '/searchunified', params)
        this.loadingUnified = false
        if (Number.isInteger(Number(this.q))) {
          let response = await this.$Amplify.API.get('cosmos', `/news/item/${this.q}`)
          this.$logger.debug(response)
          this.news = [response]
          this.loadingNews = false
        } else {
          let response = await this.$Amplify.API.get('cosmos', `/search/${this.q}?o=${this.sortingSelected}`)
          this.$logger.debug(response)
          this.news = response
          this.loadingNews = false
        }
        this.$stat.log({ page: 'search', action: 'searched', payload: { term: this.q, sorting: this.sortingSelected } })
      } catch (e) {
        this.$logger.warn('saved ERROR: ' + e)
      }
    },
    search: function (evt) {
      evt.preventDefault()
      this.$router.push({ name: 'Search', params: { q: this.q }, query: { o: this.sortingSelected } })
    },
    updateSorting: function () {
      this.$router.push({ name: 'Search', params: { q: this.q }, query: { o: this.sortingSelected } })
      window.localStorage.setItem('frmCloudSearchSorting', JSON.stringify(this.sortingSelected))
    }
  },
  watch: {
    '$route.params.q': 'load',
    '$route.query.o': 'load',
    'sortingSelected': 'updateSorting'
  }
}
</script>

<style>
</style>
